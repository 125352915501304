<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon>
            Journal Entry List
            <v-spacer></v-spacer>
            <add-button
              permission="section-create"
              @action="$router.push({ name: 'journal-entry' })"
              >New Journal
            </add-button>
          </v-card-title>

          <v-card flat>
            <v-card-title class="title" style="margin-top: -20px">
              <v-flex xs4 sm4>
                <v-text-field
                  label="From Date"
                  v-model="form.frmDate"
                  prepend-icon="event"
                  mask="####-##-##"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                  :readonly="form.loading"
                />
              </v-flex>
              <v-flex xs4 sm4>
                <v-text-field
                  label="To date"
                  v-model="form.toDate"
                  prepend-icon="event"
                  mask="####-##-##"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                  :readonly="form.loading"
                />
              </v-flex>
              <v-flex xs4 sm4>
                <v-autocomplete
                  v-model="voucher_type_head"
                  :items="voucherTypeHeads"
                  label="Select Voucher Type"
                  persistent-hint
                />
              </v-flex>
              <v-flex xs4 sm4>
                <v-autocomplete
                  v-model="journal_type_id"
                  :items="journalTypes"
                  label="Select Journal Type"
                  persistent-hint
                />
              </v-flex>
              <v-flex xs4 sm4>
                <v-text-field
                  label="Voucher No"
                  v-model="voucher_no"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 sm4>
                <add-button
                  v-if="form.frmDate"
                  icon="search"
                  :permission="'section-read'"
                  @action="searchJounal"
                  >Search
                </add-button>
              </v-flex>
            </v-card-title>
          </v-card>
        </v-card>

        <!-- Add Ledger Head -->
        <v-dialog v-model="ledgerForm.dialog" max-width="500px" persistent>
          <v-card>
            <v-card-title class="primary white--text">
              <span class="title">Ledger Head Information</span>
            </v-card-title>

            <v-card-text class="has-spinner">
              <!--<spinner light="light" :loading="ledgerForm.loading"/>-->
              <v-container fluid grid-list-sm pa-0>
                <v-form
                  ref="form"
                  @submit.prevent="store"
                  @keydown.native="ledgerForm.errors.clear($event.target.name)"
                  @keyup.enter="store"
                  lazy-validation
                >
                  <v-layout wrap>
                    <v-flex xs12 sm6>
                      <v-text-field
                        v-model="ledgerForm.name"
                        name="name"
                        label="Name"
                        :error-messages="ledgerForm.errors.get('name')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 d-flex>
                      <v-select
                        v-model="ledgerForm.account_head_id"
                        name="account_head_id"
                        :items="accountHeads"
                        label="Select Account Head"
                        :error-messages="
                          ledgerForm.errors.get('account_head_id')
                        "
                        :height="25"
                      />
                    </v-flex>
                    <v-flex xs12 sm12>
                      <v-textarea
                        v-model="ledgerForm.description"
                        name="description"
                        label="Description"
                        :error-messages="ledgerForm.errors.get('description')"
                        autocomplete="off"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="ledgerForm.reset()" outline
                >Cancel</v-btn
              >
              <v-btn
                color="info"
                :disabled="ledgerForm.errors.any() || ledgerForm.loading"
                @click="storeLedger"
                outline
                >Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="title pa-3 red white--text">
              <v-icon class="mr-2 white--text">warning</v-icon>
              Please, wait!
            </v-card-title>
            <v-card-text class="ma-0"
              >Are you sure you want to Cancel this item?
              <br />
              <v-textarea
                v-model="reason"
                outline
                name="input-7-4"
                label="Reason"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                outline
                small
                @click="(deleteDialog = false), (reason = '')"
                >No
              </v-btn>
              <v-btn
                color="error"
                outline
                small
                @click="deleteItem(), (deleteData = true)"
                :disabled="!reason"
                >Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
          :headers="headers"
          :items="form.items.data"
          class="elevation-1"
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
          :total-items="form.items.meta.total"
        >
          <template slot="items" slot-scope="props">
            <td width="5%">
              {{ props.item.journal_type ? props.item.journal_type.name : "-" }}
            </td>
            <td width="5%">
              {{ props.item.voucher_no }}
              <div>
                <i style="font-size: 10px"
                  >({{
                    props.item.journal_type
                      ? props.item.journal_type.pr_type.capitalizeFirstLetter()
                      : "-"
                  }})</i
                >
              </div>
            </td>
            <!--<td width="5%">{{props.item.journal_type ? props.item.journal_type.pr_type.capitalizeFirstLetter():'-'}}</td>-->

            <td class="text-xs-left" width="12%">
              {{ props.item.entry_date }}
            </td>
            <td class="text-xs-left" width="40%">
              <tr
                v-for="(particular, i) in props.item.ledger_accounts"
                :key="i"
              >
                <td
                  v-if="particular.dr > 0"
                  style="position: absolute; margin-left: -25px"
                >
                  {{ particular.ledger_head }} (Dr.)
                </td>

                <td
                  v-else-if="particular.cr > 0"
                  style="position: absolute; margin-left: 35px"
                >
                  To,
                  {{ particular.ledger_head }}
                </td>

                <br />
              </tr>
              <!--<span ></span>-->
              <!--<span v-else style="margin-left: 65px; position: absolute">{{particular.ledger_head}}</span>-->
              <!--<br/>-->
              <span
                ><i> (Narration: {{ props.item.narration }})</i></span
              >
            </td>

            <!--<td class="text-xs-center">{{props.item.lf_id}}</td>-->
            <td>
              <tr
                v-for="(particular, i) in props.item.ledger_accounts"
                :key="i"
              >
                <td
                  v-if="parseInt(particular.dr) > 0"
                  style="position: absolute; margin-top: -12px"
                >
                  {{ particular.dr.numberFormat() }}
                </td>
                <br />
              </tr>
            </td>

            <td>
              <tr
                v-for="(particular, i) in props.item.ledger_accounts"
                :key="i"
              >
                <td
                  v-if="parseInt(particular.cr) > 0"
                  style="position: absolute; margin-top: -12px"
                >
                  {{ particular.cr.numberFormat() }}
                </td>
                <br />
              </tr>
            </td>

            <!--<td class="text-xs-center">{{props.item.accountant.name}}</td>-->
            <td class="justify-right text-xs-right">
              <div
                style="display: inline-block"
                v-if="
                  props.item.journal_type.pr_type !== 'sales' &&
                  props.item.journal_type.pr_type !== 'purchase'
                "
              >
                <v-btn
                  @click="printVoucher(props.item.id)"
                  small
                  icon
                  color="primary"
                  class="pa-0 ma-1"
                >
                  <v-icon dark>print</v-icon>
                </v-btn>
                <!-- Edit and Delete is disabled temporarily (Do not Delete) -->
                <edit-button
                  v-if="props.item.is_sod === 0"
                  permission="fee-heads-update"
                  @agree="form.edit(props.item), editItem(props.item)"
                />

                <v-btn
                  v-if="props.item.is_sod === 0"
                  small
                  icon
                  color="error"
                  class="pa-0 ma-1"
                  @click="(deleteDialog = true), (deleteDataId = props.item.id)"
                >
                  <v-icon small>clear</v-icon>
                </v-btn>
              </div>
            </td>
          </template>
        </v-data-table>
        <!--<notification :notification="form.notification" />-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import data from "./modules/data";
import computed from "./modules/computed";
import mounted from "./modules/mounted";
import created from "./modules/created";
import methods from "./modules/methods";
import watch from "./modules/watch";

export default {
  name: "JournalList",
  // components: {Spinner,Notification},
  data: () => data,
  mounted: mounted,
  computed: computed,
  methods: methods,
  created: created,
  watch: watch,
};
</script>
<style lang="scss" scoped></style>
